import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import "../Styles/Contact.css";
import logo from "../Assets/logo_black.png";

function Contact() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    email: "",
    phone: "",
    message: "",
    subscribe: false,
  });

  const [popupVisible, setPopupVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validar que los campos obligatorios no estén vacíos
    if (!formData.name || !formData.email) {
      alert("Please fill in all required fields.");
      return;
    }

    const serviceID = "service_tljj02u";
    const templateID = "template_biifn34";
    const userID = "l3mifd19LHf9RapNr";

    emailjs
      .send(serviceID, templateID, formData, userID)
      .then((response) => {
        console.log("Email sent successfully:", response);
        setPopupVisible(true);
        setTimeout(() => setPopupVisible(false), 3000); // Ocultar popup después de 3 segundos
        setFormData({
          name: "",
          lastName: "",
          email: "",
          phone: "",
          message: "",
          subscribe: formData.subscribe ? "Yes" : "No", // Convierte el booleano a texto
        });
      })
      .catch((error) => {
        console.error("Error sending email:", error);
        alert("An error occurred. Please try again.");
      });
  };

  return (
    <div className="contact-container">
      <h1 className="contact-title">
        <img src={logo} alt="Family Insurance Logo" className="contact-logo" />
      </h1>
      <h1>Contact us!</h1>

      <form className="contact-form" onSubmit={handleSubmit}>
        <label>Name *</label>
        <input
          type="text"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />

        <label>Last Name</label>
        <input
          type="text"
          name="lastName"
          value={formData.lastName}
          onChange={handleChange}
        />

        <label>E-mail address *</label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          required
          placeholder="Ex: john@doe.com"
        />

        <label>Phone</label>
        <input
          type="tel"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="Ex: +1 300 400 5000"
        />

        <label>Message</label>
        <textarea
          name="message"
          value={formData.message}
          onChange={handleChange}
          placeholder="Example: add text here"
        ></textarea>

        <div className="contact-checkbox"> 
          <input
            type="checkbox"
            name="subscribe"
            checked={formData.subscribe}
            onChange={handleChange}
          />
          <span>
            “By choosing this option, you are signing up for text messages and
            you agree to receive SMS communications from Family Insurance
            Enterprise LLC at the phone number you provided. These messages will
            be used for marketing and customer care purposes. Your consent is
            not a condition of purchase. Message and data rates may apply.
            Message frequency may vary. You can opt out anytime by replying <strong>STOP </strong>
            or get assistance by replying <strong>HELP.</strong> For more details, view our{" "}
            <a href="/privacy-policy" className="contact-link">
              Privacy Policy
            </a>{" "}
            and
            <a href="/terms-and-conditions" className="contact-link"> Terms</a>. 
            I understand that I can withdraw my consent at any time.”
          </span>
        </div>

        <button type="submit" className="contact-button">
          Send message
        </button>
      </form>

      {popupVisible && <div className="success-popup show">Message Sent.</div>}
    </div>
  );
}

export default Contact;

