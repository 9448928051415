import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "../Styles/LegalDocs.css";
import logo from "../Assets/logo_black.png"; // Corrige la ruta de importación


function LegalDocs() {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  });

  return (
    <div className="legal-section-title">
      <h1 className="legal-siteTitle">
      <img 
              src={logo} 
              alt="Family Insurance Logo" 
              className="ft-logo" 
              style={{ width: '166px', height: 'auto', verticalAlign: 'middle' }} 
            /> 
      </h1>

      <div className="legal-text-content">
       



        <p className="legal-title">Terms and Conditions</p>
        <p className="legal-description" style={{ textAlign: 'justify' }}>
        Last update: March 30th, 2025
        <br /><br />
        <strong>Site Use: </strong> Our website is intended solely to provide comprehensive information about our health insurance services. No registration or collection of personal
         information is required to browse the site. By accessing and using our site, you agree to comply with these terms and conditions. Unauthorized use of the 
         website or misuse of any information posted on the site is strictly prohibited.
         <br /><br />
         <strong>Intellectual Property: </strong> All content on our site, including but not limited to text, images, graphics, logos, and software, is the property of Family Insurance Enterprise
        LLC and is protected by intellectual property laws. You may not reproduce, distribute, or create derivative works based on any of the content on our site without our express written permission. Any unauthorized use of our intellectual property may result in legal action.
        <br /><br />
        <strong>Limitation of Liability: </strong> We strive to provide accurate and up-to-date information on our website; however, we make no guarantees regarding the completeness, accuracy,
        or reliability of the information provided. We are not responsible for any damages, whether direct, indirect, incidental, or consequential, arising from the use of our
          website or the information provided on it. This includes, but is not limited to, damages caused by errors, omissions, or interruptions in the availability of the site.
          <br /><br />
          <strong>Modifications: </strong> We reserve the right to modify these terms and conditions at any time. Any changes will be effective immediately upon posting on our site. It is your
        responsibility to review these terms periodically to stay informed of any updates. Continued use of the site after modifications constitutes acceptance of the revised
          terms.
          <br /><br />
          <strong>Governing Law: </strong>These terms and conditions are governed by the laws of the State of Texas, without regard to its conflict of law provisions. By using our website, you
        agree that any legal action or proceeding related to your use of the site will be brought exclusively in the courts located in the State of Texas.
        <br /><br />
        <strong>Entire Agreement: </strong> These terms and conditions constitute the entire agreement between you and Family Insurance Enterprise LLC regarding the use of our website and supersede
        any prior agreements or understandings, whether written or oral, related to such use.
        <br /><br />
        <strong>Contact Information: </strong> If you have any questions or concerns regarding these terms and conditions, please contact us through the provided phone number, email address.
        We are here to ensure your experience with our services is transparent, secure, and satisfactory.
        <br /><br />
        <strong>Messaging Terms & Conditions: </strong> By selecting the checkbox in our web form, you agree to receive text messages from Family Insurance Enterprise LLC. Please review the following terms carefully:
        <br /><br />
        •	Company: (referred to as “the Company”, “We”, “Us” or “Our” in this Agreement) refers to Family Insurance Enterprise.
        <br /><br />
        •	Types of messages the consumer can expect to receive: SMS Mobile Text Messages.
        <br /><br />
        •	Message Content: These messages will be used for marketing and customer care purposes. 
        <br /><br />
        •	Message Frequency: Message frequency may vary. 
        <br /><br />
        •	Charges: Message and data rates may apply as per your mobile carrier’s terms.
        <br /><br />
        •	Opt-Out: You can opt out anytime by replying «STOP» to our messages. Once you send STOP, you will no longer receive messages from us unless you resubscribe. 
        <br /><br />
        •	Help Assistance: For any assistance or inquiries, you can reply «HELP» to our messages or contact us directly at: familyinsurancecontact@gmail.com
        <br /><br />
        •	Consent: Your consent to receive messages is not a condition of purchase.
        <br /><br />
        If you have any questions about these terms, please contact us at familyinsurancecontact@gmail.com or call us at (832) 410-4001.
        </p>

        <p className="legal-title"></p>
      

        <p className="legal-title"></p>
        
      </div>

      <div className="legal-footer">
        <p>© 2025 Family Insurance Enterprise LLC. All rights reserved.</p>
      </div>
    </div>
  );
}

export default LegalDocs;
